<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <div class="scan-manisfest-container">
                            <div class="row">
                                <template v-if="isLoading">
                                    Loading container groups...
                                </template>

                                <template v-else-if="isVirtualContainerGroup">
                                    You cannot perform any actions on these container groups.
                                </template>

                                <template v-else-if="!containerGroups?.length">
                                    There are no open container groups.
                                </template>

                                <template v-else>
                                    <div class="col-sm-6 col-lg-3" v-for="(containerGroup, key) in containerGroups" :key="key">
                                        <router-link
                                            class="text-decoration-none scan-manifest-card-link"
                                            :to="{name: 'ShippingScoutContainerGroupDetail', params: {scoutContainerGroupId: containerGroup.id}}"
                                        >
                                            <OverviewCardItem
                                                :label="containerGroup.name"
                                                :count="containerGroup.containers?.length ?? 0"
                                                :items="[{ label: 'Container group number', value: containerGroup.containerGroupNumber ?? '' }]"
                                                ref="cardItem"
                                            />
                                        </router-link>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="scan-manisfest-container pt-4">
                            <form @submit.prevent="reprintContainerLabels()">
                                <div class="form-group mb-0">
                                    <label for="rma-input" class="label-title">
                                        Reprint container documents
                                    </label>

                                    <input type="text"
                                           placeholder="Scan container code to print the container labels"
                                           class="form-control lg"
                                           id="rma-input"
                                           v-model="reprintContainerCode"
                                           ref="scanContainerCodeInput"
                                           :disabled="isLoading"
                                    >
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <InputFieldError attribute="scanContainerCodeInput" :errors="errors"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingOutboundDashboard'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import OverviewCardItem from '@/components/_OverviewCardItem.vue';
import InputFieldError from '@/components/_InputFieldError.vue';

export default {
    name: 'ScoutContainerGroupOverview',
    components: {
        'InputFieldError': InputFieldError,
        'HeaderBar': HeaderBar,
        'OverviewCardItem': OverviewCardItem,
    },
    data() {
        return {
            errors: {},
            isLoading: false,
            containerGroups: [],
            reprintContainerCode: null,
        };
    },
    created() {
        this.getOpenContainerGroups();
    },
    computed: {
        isVirtualContainerGroup() {
            return this.containerGroups?.length === 1 && (this.containerGroups[0].id === this.containerGroups[0].transportStepId);
        },
    },
    methods: {
        getOpenContainerGroups() {
            this.isLoading = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container-group/get-all');

            this.$http.get(url.href).then((data) => {
                this.isLoading = false;
                this.containerGroups = data?.data ?? [];
            });
        },
        reprintContainerLabels() {
            this.errors = {};
            this.isSaving = true;

            if (!this.reprintContainerCode || /^\s*$/.test(this.reprintContainerCode)) {
                this.errors = {reprintContainerCode: 'Enter a container shipment code!'};
                this.isSaving = false;

                return false;
            }

            const url = new URL(this.$store.state.baseUrl + 'scout-container/reprint-documents-for-closed-container');
            url.searchParams.set('containerCode', this.reprintContainerCode);

            this.$http.post(url.href).then((data) => {
                this.isSaving = false;

                if (data?.data?.code !== 200) {
                    this.errors = {scanContainerCodeInput: data.data.errors};

                    return;
                }

                this.$store.commit('showSuccessMessage', `Label for "${ this.reprintContainerCode}" container has been printed.`);

                this.reprintContainerCode = null;
            });
        }
    },
}
</script>

<style scoped>

</style>
