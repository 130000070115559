import axios from '@/classes/Axios';

const moduleStore = {
    namespaced: true,
    state: {
        availableModules: null,
    },
    mutations: {
        set(state, availableModules) {
            state.availableModules = availableModules;
        },
        clear(state) {
            state.availableModules = null;
        }
    },
    actions: {
        getAvailableModules(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.availableModules) {
                    resolve(storeArgObj.state.availableModules);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'modules/get-available-modules');

                    axios.get(url.href).then((data) => {
                        const availableModules = data.data.data;

                        storeArgObj.commit('set', availableModules);
                        resolve(availableModules);
                    });
                }
            });
        },
    }
};

export default moduleStore;
