<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="row col-12">
                <LocationDetailMenu activeCategory="Workstation" activeItem="General"/>

                <div v-if="isLoading">
                    <div class="mt-4">
                        Loading general information...
                    </div>
                </div>

                <template v-else>
                    <div class="my-4">
                        <template v-if="canUseWorkstations">
                            This location is enabled for the use of workstations.
                        </template>

                        <template v-else>
                            <InputFieldError errors="This location is not enabled for the use of workstations."/>
                        </template>
                    </div>
                </template>
            </div>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import LocationDetailMenu from '@/components/_LocationDetailMenu.vue';
import InputFieldError from '@/components/_InputFieldError.vue';

export default {
    name: 'WorkstationGeneral',
    components: {
        'HeaderBar': HeaderBar,
        'LocationDetailMenu': LocationDetailMenu,
        'InputFieldError': InputFieldError,
    },
    data() {
        return {
            isLoading: false,
            canUseWorkstations: false,
        };
    },
    created() {
        this.getCanUseWorkstations();
    },
    methods: {
        getCanUseWorkstations() {
            this.isLoading = true;

            this.$store.dispatch('workstations/getCanLocationUseWorkstations', this.$route.params.locationId).then((data) => {
                this.isLoading = false;
                this.canUseWorkstations = !!data;
            });
        }
    }
}
</script>

<style scoped>

</style>