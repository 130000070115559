import axios from '@/classes/Axios';

const returnManifestStore = {
    namespaced: true,
    state: {
        sortingFlows: {},
    },
    mutations: {
        setSortingFlows(state, sortingFlows) {
            state.sortingFlows = sortingFlows;
        },
        clear(state) {
            state.sortingFlows = {};
        }
    },
    actions: {
        getSortingFlows(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.sortingFlows && Object.keys(storeArgObj.state.sortingFlows).length > 0) {
                    resolve(storeArgObj.state.sortingFlows);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'return-manifests/get-sorting-flows');

                    axios.get(url.href).then((data) => {
                        const sortingFlows = data.data;

                        if (!sortingFlows) {
                            return false;
                        }

                        storeArgObj.commit('setSortingFlows', sortingFlows);
                        resolve(sortingFlows);
                    });
                }
            });
        }
    }
};

export default returnManifestStore;
