import Vue from 'vue';
import axios from '@/classes/Axios';
import ArrayStoreFormatter from '@/classes/ArrayStoreFormatter';

const assetStore = {
    namespaced: true,
    state: {
        unpackedAssets: {},
        inspectedAssets: {},
        customAttributes: {},
        gradingLabels: {},
        vendorResolutions: null,
        dispositionTypes: null,
        assetToView: null,
    },
    mutations: {
        setUnpackedAssets(state, [returnId, assets]) {
            Vue.set(state.unpackedAssets, returnId, assets);
        },
        setInspectedAssets(state, [returnId, assets]) {
            Vue.set(state.inspectedAssets, returnId, assets);
        },
        addUnpackedAsset(state, asset) {
            if (asset.RETURN_ID && asset.ID && state.unpackedAssets[asset.RETURN_ID]) {
                /* Only append to the unpackedAssets list if it has been populated. */
                state.unpackedAssets[asset.RETURN_ID][asset.ID] = asset;
            }
        },
        addInspectedAsset(state, asset) {
            if (asset.RETURN_ID && asset.ID && state.inspectedAssets[asset.RETURN_ID]) {
                /* Only append to the inspectedAssets list if it has been populated. */
                state.inspectedAssets[asset.RETURN_ID][asset.ID] = asset;
            }
        },
        deleteUnpackedAsset(state, asset) {
            if (state.unpackedAssets[asset.RETURN_ID]) {
                Vue.delete(state.unpackedAssets[asset.RETURN_ID], asset.ID);
            }
        },
        deleteInspectedAsset(state, asset) {
            if (state.inspectedAssets[asset.RETURN_ID]) {
                Vue.delete(state.inspectedAssets[asset.RETURN_ID], asset.ID);
            }
        },
        setCustomAttributes(state, [assetId, customAttributes]) {
            Vue.set(state.customAttributes, assetId, customAttributes);
        },
        setGradingLabels(state, [assetId, gradingLabels]) {
            Vue.set(state.gradingLabels, assetId, gradingLabels);
        },
        setVendorResolutions(state, vendorResolutions) {
            state.vendorResolutions = vendorResolutions;
        },
        setDispositionTypes(state, dispositionTypes) {
            state.dispositionTypes = dispositionTypes;
        },
        setAssetToView(state, asset) {
            state.assetToView = asset;
        },
        clearAssetToView(state) {
            state.assetToView = null;
        },
        clear(state) {
            state.unpackedAssets = {};
            state.inspectedAssets = {};
            state.customAttributes = {};
            state.gradingLabels = {};
            state.vendorResolutions = {};
            state.dispositionTypes = null;
            state.assetToView = null;
        }
    },
    actions: {
        getAllUnpackedAssets(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (returnId && storeArgObj.state.unpackedAssets && storeArgObj.state.unpackedAssets[returnId]) {
                    resolve(storeArgObj.state.unpackedAssets[returnId]);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'assets/get-all-unpacked');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        if (!data.data) {
                            resolve();
                            return false;
                        }

                        const assets = ArrayStoreFormatter.format(data.data, 'ID');
                        storeArgObj.commit('setUnpackedAssets', [returnId, assets]);
                        resolve(assets);
                    });
                }
            });
        },
        getAllInspectedAssets(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (returnId && storeArgObj.state.inspectedAssets && storeArgObj.state.inspectedAssets[returnId]) {
                    resolve(storeArgObj.state.inspectedAssets[returnId]);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'assets/get-all-inspected');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        if (!data.data) {
                            resolve();
                            return false;
                        }

                        const assets = ArrayStoreFormatter.format(data.data, 'ID');
                        storeArgObj.commit('setInspectedAssets', [returnId, assets]);
                        resolve(assets);
                    });
                }
            });
        },
        getAsset(storeArgObj, [returnId, assetId = null, productId = null]) {
            return new Promise((resolve) => {
                if (assetId && storeArgObj.state.unpackedAssets && storeArgObj.state.unpackedAssets[returnId] && storeArgObj.state.unpackedAssets[returnId][assetId]) {
                    resolve(storeArgObj.state.unpackedAssets[returnId][assetId]);
                } else if (assetId && storeArgObj.state.inspectedAssets && storeArgObj.state.inspectedAssets[returnId] && storeArgObj.state.inspectedAssets[returnId][assetId]) {
                    resolve(storeArgObj.state.inspectedAssets[returnId][assetId]);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'assets/find-by-id');

                    if (assetId) {
                        url.searchParams.set('assetId', assetId);
                    }

                    if (productId) {
                        url.searchParams.set('productId', productId);
                    }

                    axios.get(url.href).then((data) => {
                        const asset = data.data;

                        if (!asset) {
                            resolve();
                            return false;
                        }

                        if (asset.DISPOSITION_STATUS === 'unpacked') {
                            storeArgObj.commit('addUnpackedAsset', asset);
                        } else if (asset.DISPOSITION_STATUS === 'inspected') {
                            storeArgObj.commit('addInspectedAsset', asset);
                        }

                        resolve(asset);
                    });
                }
            });
        },
        //@todo add store per asser pet display type for custom attributes
        getCustomAttributes(storeArgObj, [assetId = null, returnId = null, productId = null, displayOption = null]) {
            return new Promise((resolve) => {
                const url = new URL(storeArgObj.rootState.baseUrl + 'assets/get-custom-attributes');

                if (assetId) {
                    url.searchParams.set('assetId', assetId);
                }

                if (returnId) {
                    url.searchParams.set('returnId', returnId);
                }

                if (productId) {
                    url.searchParams.set('productId', productId);
                }

                if (displayOption) {
                    url.searchParams.set('displayOption', displayOption);
                }

                axios.get(url.href).then((data) => {
                    const customAttributes = data.data;

                    if (!customAttributes) {
                        resolve();
                        return false;
                    }

                    if (assetId) {
                        storeArgObj.commit('setCustomAttributes', [assetId, customAttributes]);
                    }

                    resolve(customAttributes);
                });
            });
        },
        getGradingLabels(storeArgObj, assetId) {
            return new Promise((resolve) => {
                if (assetId && storeArgObj.state.gradingLabels && storeArgObj.state.gradingLabels[assetId]) {
                    resolve(storeArgObj.state.gradingLabels[assetId]);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'assets/get-grading-labels');
                    url.searchParams.set('assetId', assetId);

                    axios.get(url.href).then((data) => {
                        const gradingLabels = data.data;

                        if (!gradingLabels) {
                            resolve();
                            return false;
                        }

                        storeArgObj.commit('setGradingLabels', [assetId, gradingLabels]);
                        resolve(gradingLabels);
                    });
                }
            });
        },
        getVendorResolutions(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.vendorResolutions) {
                    resolve(storeArgObj.state.vendorResolutions);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'assets/get-vendor-resolutions');

                    axios.get(url.href).then((data) => {
                        const vendorResolutions = data.data;

                        if (!vendorResolutions) {
                            resolve();
                            return false;
                        }

                        storeArgObj.commit('setVendorResolutions', vendorResolutions);
                        resolve(vendorResolutions);
                    });
                }
            });
        },
        getDispositionTypes(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.dispositionTypes) {
                    resolve(storeArgObj.state.dispositionTypes);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'assets/get-disposition-types');

                    axios.get(url.href).then((data) => {
                        const dispositionTypes = data.data;

                        if (!dispositionTypes) {
                            resolve();
                            return false;
                        }

                        storeArgObj.commit('setDispositionTypes', dispositionTypes);
                        resolve(dispositionTypes);
                    });
                }
            });
        },
        getAssetToView(storeArgObj, assetId) {
            return new Promise((resolve) => {
                if (assetId && storeArgObj.state.assetToView && storeArgObj.state.assetToView.ID === assetId) {
                    resolve(storeArgObj.state.assetToView);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'assets/find-by-id');
                    url.searchParams.set('assetId', assetId);
                    url.searchParams.set('withContainer', true);
                    url.searchParams.set('withProduct', true);

                    axios.get(url.href).then((data) => {
                        resolve(data.data);
                    });
                }
            });
        }
    }
};

export default assetStore;
