import axios from '@/classes/Axios';
import Vue from 'vue';
import ArrayStoreFormatter from '@/classes/ArrayStoreFormatter';

const roleStore = {
	namespaced: true,
	state: {
		roles: null,
	},
	mutations: {
		set(state, roles) {
			state.roles = roles;
		},
		add(state, role) {
			Vue.set(state.roles, role.ID, role);
		},
		delete(state, role) {
			Vue.delete(state.roles, role.ID);
		},
		clear(state) {
			state.roles = null;
		}
	},
	actions: {
		getAll(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.roles) {
                    resolve(storeArgObj.state.roles);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'roles');
                    axios.get(url.href).then((data) => {
                        const roles = ArrayStoreFormatter.format(data.data, 'ID');
                        storeArgObj.commit('set', roles);
                        resolve(roles);
                    });
                }
            });
        },
	}
};
export default roleStore;
