import axios from '@/classes/Axios';

const articleStore = {
    namespaced: true,
    state: {
        canLocationUseWorkstations: {},
    },
    mutations: {
        setCanLocationUseWorkstations(state, [locationId, canUseWorkstations]) {
            state.canLocationUseWorkstations[locationId] = canUseWorkstations;
        },
        clear(state) {
            state.canLocationUseWorkstations = {};
        }
    },
    actions: {
        getCanLocationUseWorkstations(storeArgObj, locationId) {
            return new Promise((resolve) => {
                if (typeof storeArgObj.state.canLocationUseWorkstations?.[locationId] !== 'undefined') {
                    resolve(storeArgObj.state.canLocationUseWorkstations[locationId]);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'workstation/can-location-use-workstations');
                    url.searchParams.set('locationId', locationId);

                    axios.get(url.href).then((data) => {
                        storeArgObj.commit('setCanLocationUseWorkstations', [locationId, !!data?.data?.data]);
                        resolve(!!data?.data?.data);
                    });
                }
            });
        }
    }
};

export default articleStore;
