import axios from '@/classes/Axios';

const profileStore = {
    namespaced: true,
    state: {
        profile: {
            LOGO: null,
            DESCRIPTION: null,
            ACCESSIBILITY: null,
        }
    },
    mutations: {
        setProfile(state, profile) {
            state.profile = profile;
        },
        clear(state) {
            state.profile = {
                LOGO: null,
                DESCRIPTION: null,
                ACCESSIBILITY: null,
            };
        }
    },
    actions: {
        getProfile(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.profile.ACCESSIBILITY || storeArgObj.state.profile.DESCRIPTION || storeArgObj.state.profile.LOGO) {
                    resolve(storeArgObj.state.profile);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'profiles/get-profile');

                    axios.get(url.href).then((data) => {
                        const profile = data.data;

                        if (profile) {
                            storeArgObj.commit('setProfile', profile);
                        }

                        resolve(profile);
                    });
                }
            });
        }
    }
};

export default profileStore;
