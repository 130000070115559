<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <form action="">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="table-responsive">
                                <template v-if="isLoading">
                                    Loading container group...
                                </template>

                                <template v-else-if="!containerGroup || Object.keys(containerGroup).length === 0">
                                    Container group not found.
                                </template>

                                <template v-else>
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>Lane</th>
                                                <th>Container group number</th>
                                                <th>Status</th>
                                                <th>Containers</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="4">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ !isVirtualContainerGroup ? containerGroup.name : '-'}}
                                                                </th>

                                                                <th>
                                                                    {{ !isVirtualContainerGroup ? containerGroup.containerGroupNumber : '-' }}
                                                                </th>

                                                                <th>
                                                                    {{ !isVirtualContainerGroup ? containerGroup.state : '-' }}
                                                                </th>

                                                                <th>
                                                                    {{ !isVirtualContainerGroup ? containerGroup.containers?.length : '-' }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="4">
                                                                    <template v-if="isVirtualContainerGroup">
                                                                        <p>
                                                                            You cannot perform any actions on this container group.
                                                                        </p>
                                                                    </template>

                                                                    <template v-else-if="containerGroup.state === 'Open'">
                                                                        <p v-if="hasOpenContainers">
                                                                            All containers need to be closed before the container group can be closed.
                                                                        </p>

                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-primary p-3"
                                                                            :disabled="hasOpenContainers || isClosingContainerGroup"
                                                                            @click.prevent="closeScoutContainerGroup()"
                                                                        >
                                                                            Close container group
                                                                        </button>

                                                                        <template v-if="Object.keys(errors).length">
                                                                            <InputFieldError :errors="errors"/>
                                                                        </template>
                                                                    </template>

                                                                    <template v-else-if="containerGroup.state === 'Closed'">
                                                                        <p>
                                                                            Scan container codes to print the container labels.
                                                                        </p>

                                                                        <form action="" @submit.prevent="scanContainerReference()">
                                                                            <div class="form-group">
                                                                                <input type="text"
                                                                                       placeholder="Scan container code"
                                                                                       class="form-control lg"
                                                                                       ref="scanContainerInput"
                                                                                       v-model="containerReference"
                                                                                       :disabled="isPrintingContainerLabel"
                                                                                >
                                                                            </div>
                                                                        </form>

                                                                        <InputFieldError :errors="errors"/>
                                                                    </template>
                                                                </td>
                                                            </tr>

                                                            <template v-if="!isVirtualContainerGroup">
                                                                <tr>
                                                                    <td colspan="4">
                                                                        <table class="table-third-level">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th class="w-auto">Container number</th>
                                                                                    <th class="w-auto">Parcels</th>
                                                                                    <th class="w-auto">Status</th>
                                                                                    <th class="w-auto"></th>
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                                <tr v-for="container in containerGroup.containers" :key="container.id">
                                                                                    <td class="w-auto">
                                                                                        {{ container.containerNumber }}
                                                                                    </td>

                                                                                    <td class="w-auto">
                                                                                        {{ container.deliveryItemIds?.length }}
                                                                                    </td>

                                                                                    <td class="w-auto">
                                                                                        {{ container.state }}
                                                                                    </td>

                                                                                    <td class="w-auto">
                                                                                        <template v-if="container.state === 'Open'">
                                                                                            <router-link
                                                                                                :to="{
                                                                                                    name: 'ShippingScoutContainerDetail',
                                                                                                    params: {scoutContainerId: container.id},
                                                                                                    query: {
                                                                                                        from: 'container-group',
                                                                                                        containerGroupId: containerGroup.id
                                                                                                    }
                                                                                                }"
                                                                                            >
                                                                                                <button class="btn btn-primary btn-view-container">
                                                                                                    <span class="icon-search"></span>
                                                                                                </button>
                                                                                            </router-link>
                                                                                        </template>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <router-link :to="{name: 'ShippingScoutContainerGroupOverview'}" class="btn btn-secondary">
                                    Back
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';

export default {
    name: 'ScoutContainerGroupDetail',
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
    },
    data() {
        return {
            errors: {},
            isLoading: false,
            isClosingContainerGroup: false,
            isPrintingContainerLabel: false,
            containerGroup: null,
            containerReference: null,
        };
    },
    created() {
        this.getScoutContainerGroup();
    },
    methods: {
        getScoutContainerGroup() {
            this.isLoading = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container-group/get-container-group');
            url.searchParams.set('containerGroupId', this.$route.params.scoutContainerGroupId);

            this.$http.get(url.href).then((data) => {
                this.isLoading = false;
                this.containerGroup = data?.data ?? null;

                if (data?.data?.errors) {
                    this.errors = data.data.errors;
                }
            });
        },
        closeScoutContainerGroup() {
            this.isClosingContainerGroup = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container-group/close-container-group');
            url.searchParams.set('containerGroupId', this.containerGroup?.id);

            this.$http.post(url.href).then((data) => {
                this.isClosingContainerGroup = false;

                if (data?.data?.code === 200) {
                    this.$store.commit('showSuccessMessage', `Container group "${this.containerGroup.containerGroupNumber}" has been closed.`);
                    this.getScoutContainerGroup();
                } else {
                    this.errors = 'Something went wrong while trying to close the container group.';
                }
            });
        },
        scanContainerReference() {
            this.errors = {};

            const targetContainer = this.containerGroup?.containers?.find((container) => container.containerNumber === this.containerReference);

            if (!targetContainer) {
                this.errors = `No container found with code "${this.containerReference}"`;
                return;
            }

            this.isPrintingContainerLabel = true;

            const printUrl = new URL(this.$store.state.baseUrl + 'scout-container/print-documents');
            printUrl.searchParams.set('containerId', targetContainer.id);

            this.$http.get(printUrl.href).then((data) => {
                this.isPrintingContainerLabel = false;

                if (data?.data?.errors) {
                    this.errors = data.data.errors;
                } else {
                    this.$store.commit('showSuccessMessage', `Shipping label for container "${this.containerReference}" has been printed.`);
                    this.containerReference = null;
                }
            });
        }
    },
    computed: {
        isVirtualContainerGroup() {
            return this.containerGroup.id === this.containerGroup.transportStepId;
        },
        hasOpenContainers() {
            return !!this.containerGroup?.containers?.filter((container) => container.state === 'Open')?.length;
        }
    }
}
</script>

<style scoped>
td .btn-view-container {
    width: 48px;
    height: 48px;
}
</style>
