import axios from '@/classes/Axios';

const articleStore = {
    namespaced: true,
    state: {
        articles: {},
    },
    mutations: {
        setArticles(state, [articleGroupId, articles]) {
            state.articles[articleGroupId] = articles;
        },
        clear(state) {
            state.articles = {};
        }
    },
    actions: {
        getAllFromGroup(storeArgObj, articleGroupId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.articles && storeArgObj.state.articles[articleGroupId]) {
                    resolve(storeArgObj.state.articles[articleGroupId]);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'articles/get-articles-from-group');
                    url.searchParams.set('articleGroupId', articleGroupId);

                    axios.get(url.href).then((data) => {
                        const articles = data.data;

                        if (!articles) {
                            return false;
                        }

                        storeArgObj.commit('setArticles', [articleGroupId, articles]);
                        resolve(articles);
                    });
                }
            });
        }
    }
};

export default articleStore;
