import axios from '@/classes/Axios';

const userSettingsStore = {
    namespaced: true,
    state: {
        selectedLocation: null,
        selectedWorkstation: null,
        operatorLocation: null,
        operatorWorkstation: null,
    },
    mutations: {
        setSelectedLocation(state, location) {
            state.selectedLocation = location;
        },
        setSelectedWorkstation(state, workstation) {
            state.selectedWorkstation = workstation;
        },
        setOperatorLocation(state, location) {
            state.operatorLocation = location;
        },
        setOperatorWorkstation(state, workstation) {
            state.operatorWorkstation = workstation;
        },
        clearSelectedWorkstation(state) {
            state.selectedWorkstation = null;
        },
        clearOperatorWorkstation(state) {
            state.operatorWorkstation = null;
        },
        clear(state) {
            state.selectedLocation = null;
            state.selectedWorkstation = null;
            state.operatorLocation = null;
            state.operatorWorkstation = null;
        }
    },
    actions: {
        getSelectedLocation(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.selectedLocation) {
                    resolve(storeArgObj.state.selectedLocation);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'user-settings/get-selected-location');

                    axios.get(url.href).then((data) => {
                        let selectedLocation = null;

                        if (data.data.code === 100) {
                            selectedLocation = data.data.data.selectedLocation;
                        }

                        storeArgObj.commit('setSelectedLocation', selectedLocation);
                        resolve(selectedLocation);
                    });
                }
            });
        },
        getSelectedWorkstation(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.selectedWorkstation) {
                    resolve(storeArgObj.state.selectedWorkstation);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'user-settings/get-selected-workstation');

                    axios.get(url.href).then((data) => {
                        let selectedWorkstation = null;

                        if (data.data.code === 100) {
                            selectedWorkstation = data.data.data.selectedWorkstation;
                        }

                        storeArgObj.commit('setSelectedWorkstation', selectedWorkstation);
                        resolve(selectedWorkstation);
                    });
                }
            });
        },
        getOperatorLocation(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.operatorLocation) {
                    resolve(storeArgObj.state.operatorLocation);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'user-settings/get-operator-location');

                    axios.get(url.href).then((data) => {
                        let operatorLocation = null;

                        if (data?.data.code === 100) {
                            operatorLocation = data.data.data;
                        }

                        storeArgObj.commit('setOperatorLocation', operatorLocation);
                        resolve(operatorLocation);
                    });
                }
            });
        },
        getOperatorWorkstation(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.operatorWorkstation) {
                    resolve(storeArgObj.state.operatorWorkstation);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'user-settings/get-operator-workstation');

                    axios.get(url.href).then((data) => {
                        let operatorWorkstation = null;

                        if (data?.data.code === 100) {
                            operatorWorkstation = data.data.data;
                        }

                        storeArgObj.commit('setOperatorWorkstation', operatorWorkstation);
                        resolve(operatorWorkstation);
                    });
                }
            });
        }
    }
};

export default userSettingsStore;
