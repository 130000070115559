<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="row col-12">
                <LocationDetailMenu activeCategory="Workstation" activeItem="Overview"/>

                <div v-if="isLoading">
                    <div class="mt-4">
                        Loading workstation information...
                    </div>
                </div>

                <div v-else-if="!workstations?.length">
                    <div class="mt-4">
                        No workstations found.
                    </div>
                </div>

                <template v-else>
                    <div class="col-10">
                        <div class="table-responsive mt-4">
                            <table class="w-100">
                                <thead>
                                    <tr>
                                        <th>
                                            Workstation
                                        </th>

                                        <th>
                                            Computer
                                        </th>

                                        <th>
                                            Active
                                        </th>

                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(workstation, key) in workstations" :key="key">
                                        <td>
                                            {{ workstation.NAME }}
                                        </td>

                                        <td>
                                            {{ workstation.PRINTNODE_DESKTOP_NAME || '-' }}
                                        </td>

                                        <td>
                                            <span class="text-success" v-if="workstation.ACTIVE === 1">
                                                Active
                                            </span>

                                            <span class="text-danger" v-else>
                                                Inactive
                                            </span>
                                        </td>

                                        <td>
                                            <router-link
                                                :to="{name: 'WorkstationUpdate', params: {workstationId: workstation.ID}}"
                                                class="btn btn-primary sm mr-3"
                                            >
                                                Update
                                            </router-link>

                                            <button class="btn btn-danger sm" @click.prevent="showDeleteWorkstationConfirmationModal(workstation)">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
            </div>
        </main>

        <ConfirmationPopup
            v-if="showDeleteConfirmationModal"
            :message="`Are you sure you want to delete workstation ${workstationToDelete.NAME || ''}?`"
            :is-loading="isDeletingWorkstation"
            :loading-message="`Deleting workstation ${workstationToDelete.NAME || ''}...`"
            @confirm="deleteWorkstation(workstationToDelete.ID)"
            @deny="showDeleteConfirmationModal = false"
        >
            <template #additional-content>
                <InputFieldError :errors="errors" />
            </template>
        </ConfirmationPopup>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import LocationDetailMenu from '@/components/_LocationDetailMenu.vue';
import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
import InputFieldError from '@/components/_InputFieldError.vue';

export default {
    name: 'WorkstationOverview',
    components: {
        'HeaderBar': HeaderBar,
        'LocationDetailMenu': LocationDetailMenu,
        'ConfirmationPopup': ConfirmationPopup,
        'InputFieldError': InputFieldError,
    },
    data() {
        return {
            errors: {},
            isLoading: false,
            isDeletingWorkstation: false,
            workstations: [],
            showDeleteConfirmationModal: false,
            workstationToDelete: {},
        };
    },
    created() {
        this.getWorkstations();
    },
    methods: {
        getWorkstations() {
            this.isLoading = true;
            const url = new URL(this.$store.state.baseUrl + 'workstation/get-all-by-location');
            url.searchParams.set('locationId', this.$route.params.locationId);

            this.$http.get(url.href).then((data) => {
                this.isLoading = false;
                this.workstations = data?.data?.data || [];
            });
        },
        showDeleteWorkstationConfirmationModal(workstation) {
            this.errors = {};
            this.workstationToDelete = workstation;
            this.showDeleteConfirmationModal = true;
        },
        deleteWorkstation(workstationId) {
            this.errors = {};
            this.isDeletingWorkstation = true;

            const url = new URL(this.$store.state.baseUrl + 'workstation/delete');
            const params = new URLSearchParams();
            params.append('Workstation[ID]', workstationId);
            params.append('Workstation[LOCATION_ID]', this.$route.params.locationId);

            this.$http.post(url.href, params).then((data) => {
                this.isDeletingWorkstation = false;

                if (data?.data?.code === 200) {
                    this.$store.commit('showSuccessMessage', `Workstation ${this.workstationToDelete.NAME} has been deleted.`);
                    this.showDeleteConfirmationModal = false;
                    this.workstationToDelete = {};
                    this.getWorkstations();
                } else if (data?.data?.errors) {
                    this.errors = data.data.errors;
                } else {
                    this.errors = 'Something went wrong while trying to delete the workstation.';
                }
            });
        }
    }
}
</script>

<style scoped>
table tbody tr:hover {
    background: #f6f6f6;
}

table th, table td {
    padding: 5px;
}
</style>
