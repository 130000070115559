import axios from '@/classes/Axios';

const articleGroupStore = {
    namespaced: true,
    state: {
        articleGroups: {},
    },
    mutations: {
        setArticleGroups(state, [returnId, articleGroups]) {
            state.articleGroups[returnId] = articleGroups;
        },
        clear(state) {
            state.articleGroups = {};
        }
    },
    actions: {
        getAll(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.articleGroups && storeArgObj.state.articleGroups[returnId]) {
                    resolve(storeArgObj.state.articleGroups[returnId]);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'article-groups/get-article-groups');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        const articleGroups = data.data;

                        if (!articleGroups) {
                            return false;
                        }

                        storeArgObj.commit('setArticleGroups', [returnId, articleGroups]);
                        resolve(articleGroups);
                    });
                }
            });
        }
    }
};

export default articleGroupStore;
