import axios from '@/classes/Axios';

const locationMerchantSettingsStore = {
    namespaced: true,
    state: {
        receiveAndGradeFlows: {},
    },
    mutations: {
        setReceiveAndGradeFlows(state, receiveAndGradeFlows) {
            state.receiveAndGradeFlows = receiveAndGradeFlows;
        },
        clear(state) {
            state.receiveAndGradeFlows = {};
        },
    },
    actions: {
        getReceiveAndGradeFlows(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.receiveAndGradeFlows && Object.keys(storeArgObj.state.receiveAndGradeFlows).length > 0) {
                    resolve(storeArgObj.state.receiveAndGradeFlows);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'location-merchant-settings/get-receive-and-grade-flows');

                    axios.get(url.href).then((data) => {
                        const receiveAndGradeFlows = data.data;

                        if (!receiveAndGradeFlows) {
                            return false;
                        }

                        storeArgObj.commit('setReceiveAndGradeFlows', receiveAndGradeFlows);
                        resolve(receiveAndGradeFlows);
                    });
                }
            });
        }
    }
};

export default locationMerchantSettingsStore;
