<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <form action="">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="table-responsive">
                                <template v-if="isLoading">
                                    Loading container...
                                </template>

                                <template v-else-if="!container || Object.keys(container).length === 0">
                                    No container found with code "{{ $route.params.scoutContainerId }}"
                                </template>

                                <template v-else>
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>Container</th>
                                                <th>Parcels</th>
                                                <th>Lane</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="3">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ container.containerNumber }}
                                                                </th>

                                                                <th>
                                                                    {{ container.deliveryItemIds?.length }}
                                                                </th>

                                                                <th>
                                                                    {{ lane }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="3">
                                                                    <template v-if="container.state === 'Open'">
                                                                        <p v-if="!container.deliveryItemIds?.length">
                                                                            There are no parcels assigned to this container.
                                                                            This means the container will be deleted, instead of closed.
                                                                        </p>

                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-primary p-3"
                                                                            :disabled="isClosingContainer || isPrintingContainer"
                                                                            @click.prevent="handleCloseContainer()"
                                                                        >
                                                                            Close container
                                                                        </button>

                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-secondary p-3 ml-3"
                                                                            :disabled="isClosingContainer || isPrintingContainer"
                                                                            @click.prevent="handleReprintContainer()"
                                                                        >
                                                                            Print container label
                                                                        </button>

                                                                        <template v-if="Object.keys(errors).length">
                                                                            <InputFieldError attribute="closeContainer" :errors="errors"/>
                                                                        </template>
                                                                    </template>

                                                                    <template v-else-if="container.state === 'Closed'">
                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-primary p-3"
                                                                            :disabled="isReopeningContainer"
                                                                            @click.prevent="handleReopenContainer()"
                                                                        >
                                                                            Reopen container
                                                                        </button>

                                                                        <template v-if="Object.keys(errors).length">
                                                                          <InputFieldError :errors="errors"/>
                                                                        </template>
                                                                    </template>
                                                                </td>
                                                            </tr>

                                                            <tr v-if="container.state === 'Open' && container.deliveryItemIds?.length > 0">
                                                                <td colspan="3">
                                                                    <form @submit.prevent="scanReference()">
                                                                        <div class="form-group mb-0">
                                                                            <label for="rma-input" class="label-title">
                                                                                Move a parcel
                                                                            </label>

                                                                            <input type="text"
                                                                               placeholder="Scan parcel shipment tracking code"
                                                                               class="form-control lg"
                                                                               id="rma-input"
                                                                               v-model="parcelReference"
                                                                               ref="scanReferenceInput"
                                                                               :disabled="isLoading || isSaving || isLoadingParcel || isLoadingContainers"
                                                                            >
                                                                        </div>

                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <InputFieldError attribute="parcelReference" :errors="errors"/>
                                                                            </div>
                                                                        </div>
                                                                    </form>

                                                                    <div class="scan-manisfest-container my-3 p-3">
                                                                        <div class="row scout-containers-container">
                                                                            <template v-if="hasScannedValidParcel">
                                                                                <div class="col-12 mb-4">
                                                                                    <button class="btn btn-secondary btn-md"
                                                                                          @click.prevent="openNewContainer()"
                                                                                          :disabled="isLoadingContainers || isCreatingContainer || isSaving"
                                                                                    >
                                                                                        Create new container
                                                                                    </button>

                                                                                    <InputFieldError attribute="createContainer" :errors="errors"/>
                                                                                </div>

                                                                                <template
                                                                                    v-if="containers?.length"
                                                                                    v-for="container in containers">
                                                                                    <div class="col-sm-6 col-lg-3"
                                                                                       v-if="container.ID !== $route.params.scoutContainerId"
                                                                                       :key="container.ID"
                                                                                    >
                                                                                        <OverviewCardItem
                                                                                            :label="container.NAME"
                                                                                            :count="container.DELIVERY_ITEMS"
                                                                                            :items="[{label: 'Lane', value: container.LANE}]"
                                                                                            class="my-3"
                                                                                        />
                                                                                    </div>
                                                                                </template>

                                                                                <template v-else-if="isLoadingContainers">
                                                                                    <div class="col-12">
                                                                                        Loading available containers...
                                                                                    </div>
                                                                                </template>

                                                                                <template v-else>
                                                                                    <div class="col-12">
                                                                                        No open containers found.
                                                                                      </div>
                                                                                </template>
                                                                            </template>

                                                                            <template v-else>
                                                                                <div class="col-12">
                                                                                    Scan a parcel in order to see the available containers.
                                                                                  </div>
                                                                            </template>
                                                                        </div>
                                                                    </div>

                                                                    <form @submit.prevent="scanContainer()">
                                                                        <div class="form-group mb-0">
                                                                            <input type="text"
                                                                                   placeholder="Scan container code"
                                                                                   class="form-control lg"
                                                                                   :disabled="!scanContainerInputActive || isSaving"
                                                                                   v-model="containerCode"
                                                                                   ref="scanContainerInput"
                                                                            >
                                                                        </div>

                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <InputFieldError attribute="containerCode" :errors="errors"/>
                                                                            </div>
                                                                        </div>
                                                                    </form>

                                                                    <p class="saving-message" v-if="isSaving">
                                                                        Move parcel "{{ parcelReference }}" to manifest "{{ containerCode }}"...
                                                                    </p>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <router-link :to="backRoute" class="btn btn-secondary">
                                    Back
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>

    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import OverviewCardItem from '@/components/_OverviewCardItem.vue';

export default {
    name: 'ScoutContainerDetail',
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
        'OverviewCardItem': OverviewCardItem,
    },
    data() {
        return {
            errors: {},
            isLoading: false,
            isSaving: false,
            isLoadingParcel: false,
            isClosingContainer: false,
            isReopeningContainer: false,
            isLoadingContainers: false,
            isCreatingContainer: false,
            isPrintingContainer: false,
            hasScannedValidParcel: false,
            scanContainerInputActive: false,
            container: null,
            containers: [],
            containerCode: null,
            lane: null,
            parcelReference: null,
        };
    },
    created() {
        this.getScoutContainer();
    },
    methods: {
        getScoutContainer() {
            this.isLoading = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container/find-container');
            url.searchParams.set('containerReference', this.$route.params.scoutContainerId);

            this.$http.get(url.href).then((data) => {
                this.isLoading = false;

                if (data?.data?.code === 404) {
                    this.errors = {closeContainer: `No container found with code "${this.$route.params.scoutContainerId}"`};
                } else if (data?.data?.code !== 200) {
                    this.errors = {closeContainer: `Something went wrong while trying to retrieve the container with code "${this.$route.params.scoutContainerId}"`};
                } else {
                    this.container = data?.data?.data?.container ?? null;
                    this.lane = data?.data?.data?.lane ?? null;
                }
            });
        },
        getScoutContainers() {
            this.isLoadingContainers = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container-group/get-containers');
            url.searchParams.set('transportStepId', this.parcelNextTransportStepId);
            url.searchParams.set('state', 'Open');

            this.$http.get(url.href).then((data) => {
                this.isLoadingContainers = false;
                this.containers = data?.data ?? [];

                if (this.containers?.length) {
                  this.scanContainerInputActive = true;

                  this.$nextTick(() => {
                      this.$refs.scanContainerInput?.focus();
                  });
                }
            });
        },
        handleCloseContainer() {
            if (this.container.deliveryItemIds?.length) {
                this.closeScoutContainer();
            } else {
                this.deleteScoutContainer();
            }
        },
        handleReopenContainer() {
            if (this.container.state !== 'Closed') {
                this.errors = 'This container is not closed. Cannot reopen this container.';

                return;
            }

            this.isReopeningContainer = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container/reopen-container');
            url.searchParams.set('containerId', this.container?.id);

            this.$http.get(url.href).then((data) => {
                this.isReopeningContainer = false;

                if (data?.data?.code === 200) {
                    this.$store.commit('showSuccessMessage', `Container "${this.container.containerNumber}" has been reopened.`);

                    this.$router.push(this.backRoute);
                } else {
                    this.errors = 'Something went wrong while trying to reopening the container.';
                }
            });
        },
        closeScoutContainer() {
            this.isClosingContainer = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container/close-container');
            url.searchParams.set('containerId', this.container?.id);

            this.$http.get(url.href).then((data) => {
                this.isClosingContainer = false;

                if (data?.data?.code === 200) {
                    this.$store.commit('showSuccessMessage', `Container "${this.container.containerNumber}" has been closed.`);

                    this.$router.push(this.backRoute);
                } else {
                    this.errors = {closeContainer: data.data.errors};
                }
            });
        },
        openNewContainer() {
            if (!this.parcelNextTransportStepId) {
                this.errors = {createContainer: 'Unable to create a container. Missing required transport step information from the parcel.'};
            }

            this.isCreatingContainer = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container/create-container');
            url.searchParams.set('transportStepId', this.parcelNextTransportStepId);

            this.$http.get(url.href).then((data) => {
                this.isCreatingContainer = false;

                if (!data?.data?.code === 404) {
                    this.errors = {createContainer: 'Something went wrong while trying to create a container.'};

                    return;
                }

                this.getScoutContainers();
            });
        },
        deleteScoutContainer() {
            this.isClosingContainer = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container/delete-container');
            url.searchParams.set('containerId', this.container?.id);

            this.$http.get(url.href).then((data) => {
                this.isClosingContainer = false;

                if (data?.data?.code === 200) {
                    this.$store.commit('showSuccessMessage', `Container "${this.container.containerNumber}" has been closed.`);

                    this.$router.push(this.backRoute);
                } else {
                    this.errors = {closeContainer: 'Something went wrong while trying to close the container.'};
                }
            });
        },
        handleReprintContainer() {
            this.errors = {};
            this.isPrintingContainer = true;

            const url = new URL(this.$store.state.baseUrl + 'scout-container/reprint-documents');
            url.searchParams.set('containerId', this.container?.id);

            this.$http.post(url.href).then((data) => {
                this.isPrintingContainer = false;

                if (data?.data?.code !== 200) {
                    this.errors = {closeContainer: data.data.errors};

                    return;
                }

                this.$store.commit('showSuccessMessage', `Label for "${ this.container.containerNumber}" container has been printed.`);
            });
        },
        scanContainer() {
            this.errors = {};
            this.isSaving = true;

            if (!this.containerCode || /^\s*$/.test(this.containerCode)) {
                this.errors = {containerCode: 'Enter a container shipment code!'};
                this.isSaving = false;

                return false;
            }

            if (this.inContainer !== this.container.containerNumber) {
                this.errors = {containerCode: `The parcel "${this.parcelReference}" is not part of "${this.container.containerNumber}" container.`};
                this.isSaving = false;

                return false;
            }

            if (this.containerCode === this.container.containerNumber) {
                this.errors = {containerCode: `Cannot move "${this.parcelReference}" parcel when its in the current container.`};
                this.isSaving = false;

                return false;
            }

            const container = this.containers.find(item => item.NAME === this.containerCode);
            if (!container) {
                this.errors = {containerCode: `There is no open container with "${this.containerCode}" container code.`};
                this.isSaving = false;

                return false;
            }

            const url = new URL(this.$store.state.baseUrl + 'scout-container/move-parcel-to-container');

            url.searchParams.set('currentContainerId', this.$route.params.scoutContainerId);
            url.searchParams.set('newContainerId', container.ID);

            const params = new URLSearchParams();
            params.append('PARCEL_GUID', this.deliveryItemId);
            this.$http.post(url.href, params).then((data) => {
                this.isSaving = false;

                if (data?.data?.code === 404) {
                    this.errors = {containerCode: data.data.errors};

                    this.$nextTick(() => {
                        this.$refs.scanContainerInput.focus();
                    });
                } else if (data?.data?.code !== 200) {
                    this.errors = {containerCode: 'Something went wrong while trying to assign the parcel to the container.'};

                    this.$nextTick(() => {
                        this.$refs.scanContainerInput.focus();
                    });
                } else {
                    this.$store.commit('showSuccessMessage', `Parcel "${this.parcelReference}" has been added to "${ this.containerCode}" container.`);

                    this.parcelReference = null;
                    this.clearData();

                    this.getScoutContainer();

                    /* Focus on the "Scan reference" input field after activating it. */
                    this.$nextTick(() => {
                        this.$refs.scanReferenceInput.focus();
                    });
                }
            });
        },
        scanReference() {
            this.isLoadingParcel = true;
            this.errors = {};
            this.clearData();

            /* Check if the reference is empty, or exists of only spaces */
            if (!this.parcelReference || /^\s*$/.test(this.parcelReference)) {
                this.errors = {parcelReference: 'Enter a parcel reference.'};
                this.isLoadingParcel = false;

                return false;
            }

            const url = new URL(this.$store.state.baseUrl + 'shipment-item/request-new');
            url.searchParams.set('barcode', this.parcelReference);
            url.searchParams.set('state', 'Open');

            this.$http.get(url.href).then((data) => {
                this.isLoadingParcel = false;

                /*
                 * Response code 404:
                 * - Show the error message
                 *
                 * Response code 200:
                 * - The parcel can be received to a sorted manifest.
                 */
                if (data.data.code === 404) {
                    this.errors = {parcelReference: data.data.errors};

                    this.$nextTick(() => {
                      this.$refs.scanReferenceInput?.focus();
                    });
                } else {
                    this.hasScannedValidParcel = true;

                    this.parcelNextTransportStepId = data?.data?.data?.NEXT_TRANSPORT_STEP_ID;
                    this.deliveryItemId = data?.data?.data?.DELIVERY_ITEM_ID;
                    this.inContainer = data?.data?.data?.IN_CONTAINER;
                    this.containers = data?.data?.data?.CONTAINERS ?? [];

                    if (this.inContainer !== this.container.containerNumber) {
                        this.errors = {parcelReference: `The parcel "${this.parcelReference}" is not part of the current "${this.container.containerNumber}" container.`};

                        this.hasScannedValidParcel = false;

                        return false;
                    }

                    if (this.containers?.length) {
                        this.scanContainerInputActive = true;

                        this.$nextTick(() => {
                            this.$refs.scanContainerInput?.focus();
                        });
                    }
                }
            });
        },
        clearData() {
            this.containerCode = null;
            this.scanContainerInputActive = false;
            this.containers = [];
            this.hasScannedValidParcel = false;
        }
    },
    computed: {
        backRoute() {
            if (this.$route.query.from === 'container-group') {
                if (this.$route.query.containerGroupId) {
                    return {
                        name: 'ShippingScoutContainerGroupDetail',
                        params: {scoutContainerGroupId: this.$route.query.containerGroupId}
                    };
                }

                return {name: 'ShippingScoutContainerGroupOverview'};
            }

            return {name: 'ShippingScoutContainerOverview'};
        },
    }
}
</script>

<style scoped>

</style>
