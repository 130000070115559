class ArrayStoreFormatter {
    /*
     * map numeric to key value
     */
    static format(array, key) {
        const result = {};

        for(const i in array) {
            const row = array[i];
            result[row[key]] = row;
        }
        return result;
    }
}

export default ArrayStoreFormatter;
