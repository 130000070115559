import axios from '@/classes/Axios';
import Vue from 'vue';
import ArrayStoreFormatter from '@/classes/ArrayStoreFormatter';

const userStore = {
	namespaced: true,
	state: {
		currentUser: null,
		agents: null,
	},
	mutations: {
		setCurrentUser(state, user) {
			state.currentUser = user;
		},
		setAgents(state, agents) {
			state.agents = agents;
		},
		setAgent(state, agent) {
			Vue.set(state.agents, agent.ID, agent);
		},
		deleteAgent(state, id) {
			Vue.delete(state.agents, id);
		},
		logout(state) {
			state.currentUser = null;
			state.agents = null;
		}
	},
	actions: {
		getCurrentUser(storeArgObj) {
			return new Promise((resolve) => {
				if (storeArgObj.state.currentUser) {
					resolve(storeArgObj.state.currentUser);
				} else {
					const url = new URL(storeArgObj.rootState.baseUrl + 'users/currentuser');
					axios.get(url.href).then((data) => {
						let user = null;

						if (data.data) {
							user = data.data.data.user;
							storeArgObj.commit('setCurrentUser', user);
						}

						resolve(user);
					});
				}
			});
		},
		logout(storeArgObj) {
			return new Promise((resolve) => {
				const url = new URL(storeArgObj.rootState.baseUrl + 'users/logout');
				axios.get(url.href).then(() => {
					storeArgObj.commit('logout');
					resolve();
				});
			});
		},
		getAgents(storeArgObj) {
			return new Promise((resolve) => {
				if (storeArgObj.state.agents) {
					resolve(storeArgObj.state.agents);
				} else {
					const url = new URL(storeArgObj.rootState.baseUrl + 'agents');
					axios.get(url.href).then((data) => {
						const agents = ArrayStoreFormatter.format(data.data, 'ID');
						storeArgObj.commit('setAgents', agents);
						resolve(agents);
					});
				}
			});
		}
	}
};
export default userStore;
